.company-info {
  padding: 10px;
  padding-top: 20px;
}

.center {
  text-align: center;
}

.info-container {
  padding: 10px 0px;
}

.info-container h5 {
  font-weight: bold;
}

.company-info .company-name {
  font-size: 25px;
}

.company-info .apply-link {
  float: right;
  transform: translateY(50%);
}

.other-req-header {
  color: var(--main-green);
  font-weight: bold;
  display: inline-block;
}

.other-req {
  padding: 10px;
  font-size: 14px;
}

.requirements {
  padding-top: 10px;
}

.data-vis {
  border-radius: 5px;
  border: 1px solid var(--main-green);
  padding: 20px;
  position: relative;
  height: 150px;
  width: 250px;
}

.data-vis-title {
  color: var(--main-green);
  position:absolute;
  bottom: 5px;
  right: 5px;
  font-weight: bold;
  font-size: 12px;
  flex-grow: 1;
}

.data-vis-avg-earnings {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  font-weight: bold;
}

.data-vis-avg-earnings h1 {
  font-size: 2rem;
}

.data-vis-avg-earnings p {
  font-size: .8rem;
  float: right;
}

.distribution-container {
  /* min-height: 90px; */
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.distribution-container-info {
  display: flex;
  align-items: center;
}

.distribution-container-labels {
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
}

.column {
  flex-grow: 1;
  border-right: 1px dotted black;
  text-align: center;
  position: relative;
  padding: 8px;
}

.column:last-child {
  border-right: none;
}

.marker {
  margin: auto; 
  font-size: larger;
  font-weight: bold;
}

.marker-percent {
  font-weight: bold;
  font-size: small;
  vertical-align: middle;
  vertical-align: +2px;
}

.temp-text {
  flex-grow: 1;
  text-align: center;
  position: relative;
  font-size: 10px;
}
/* 
.temp-text::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 1px;
  height: 100%;
  border-right: 1px dotted black;
  transform: translateX(-50%);
} */
