:root {
  --main-color: #32325d;;
  --light-grey: #F5F6FA;;
  --yellow-grey: #FAFAF9;
  --light-yellow: #FCF8D1;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.089);
}

.chakra-card__body {
  border-radius: 10px;
}

.chakra-card__body:hover {
  box-shadow: -1px 1px 10px rgb(178 190 181 / 64%);
  border-radius: 10px;
  transition: 0.25s;
}

.evergreen { 
  float: right;
}
/*
EmojiSymbols Font (c)blockworks - Kenichi Kaneko
http://emojisymbols.com/
*/
@font-face {
  font-family: "EmojiSymbols";
  src: url('EmojiSymbols-Regular.woff') format('woff');
  text-decoration: none;
  font-style: normal;
}

.symbol {
  font-family: "EmojiSymbols";
  display: inline;
}

.description {
  font-weight: normal;
}