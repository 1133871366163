/*
EmojiSymbols Font (c)blockworks - Kenichi Kaneko
http://emojisymbols.com/
*/
@font-face {
  font-family: "EmojiSymbols";
  src: url('EmojiSymbols-Regular.woff') format('woff');
  text-decoration: none;
  font-style: normal;
}

.symbol {
  font-family: "EmojiSymbols";
  display: inline;
}